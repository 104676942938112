<style scoped lang="less">
  .illness-case {
    table {
      width: 100%;
      td {
        color: #333333;
        span {
          color: #666;
        }
      }
    }
    .prescription {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="illness-case">
    <table>
      <tr>
        <td><span>就诊时间:</span> {{illnessCase.diagnosisData.createTime}}</td>
        <td><span>就诊类型:</span> {{illnessCase.diagnosisData.type}}</td>
        <td><span>性别:</span> {{illnessCase.patientData.type}}</td>
        <td><span>年龄:</span> {{illnessCase.patientData.age}}岁</td>
        <td><span>开方人:</span> {{illnessCase.diagnosisData.executorName}}</td>
        <td><span>医嘱:</span> {{illnessCase.prescriptionGroupData ? illnessCase.prescriptionGroupData.docRequire : '无'}}</td>
      </tr>
      <tr colspan="5">
        <td><span>发病日期:</span> {{illnessCase.diagnosisData.startTime}}</td>
      </tr>
      <tr colspan="5">
        <td><span>诊断信息:</span> {{illnessCase.diagnosisData.result}}</td>
      </tr>
      <tr colspan="5">
        <td><span>家庭地址:</span> {{illnessCase.diagnosisData.address}}</td>
      </tr>
    </table>
    <div v-for="(prescription, i) in prescriptionData" :key="i + '-' + prescription.id" class="prescription">
      <component :data="prescription.data" :is="prescription.component"></component>
    </div>
  </div>
</template>

<script>
import { types } from '@/views/medicine/lib'

export default {
  props: {
    illnessCase: { type: Object, default: null }
  },
  computed: {
    prescriptionData () {
      let prescriptionData = []

      if (this.illnessCase.prescriptionGroupData) {
        const dataTypes = Array.from(new Set(this.illnessCase.prescriptionGroupData.prescriptionData.map(v => v.type)))

        prescriptionData = dataTypes.map(type => {
          const action = types.find(v => v.value === type).action
          return {
            component: () => import('./' + action),
            data: {
              action: action,
              type: type,
              prescriptionData: this.illnessCase.prescriptionGroupData.prescriptionData.filter(v => v.type === type),
            }
          }
        })
      }
      
      return prescriptionData
    }
  }
}
</script>
