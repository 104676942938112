<style scoped lang="less">
  .info {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
  }
  .table {
    color: #666;
    font-size: 15px;
    tr {
      margin-top: 10px;
    }
    td {
      padding: 5px 0;
      min-width: 300px;
      span {
        color: #333;
      }
    }
  }
</style>

<template>
  <div class="info">
    <fm-tabs full>
      <fm-tab-pane title="个人信息">
        <TitleCard class="card" title="基本信息">
          <fm-btn slot="action" size="small" @click="status.form = true">修改</fm-btn>
          <table class="table">
            <tr>
              <td>姓名：<span>{{data.name}}</span></td>
              <td>联系方式：<span>{{data.phone}}</span></td>
            </tr>
            <tr>
              <td>性别：<span>{{data.sex}}</span></td>
              <td>身份证号：<span>{{data.idNo}}</span></td>
            </tr>
            <tr>
              <td>年龄：<span>{{data.age}}</span></td>
              <td>居住地址：<span>{{data.address}}</span></td>
            </tr>
          </table>
        </TitleCard>
        <TitleCard style="margin-top: 10px" class="card" title="患者病史">
          <fm-btn slot="action" size="small" @click="status.form = true">修改</fm-btn>
          <div style="padding: 10px;">
            <table class="table">
              <tr>
                <td>过敏史：<span>{{data.hisAllergy}}</span></td>
                <td>既往史：<span>{{data.hisPast}}</span></td>
              </tr>
              <tr>
                <td>家族史：<span>{{data.hisFamily}}</span></td>
                <td>个人史：<span>{{data.hisPersonal}}</span></td>
              </tr>
            </table>
          </div>
        </TitleCard>
      </fm-tab-pane>
      <fm-tab-pane title="就诊记录">
        <CasesTable style="position: relative; top: -20px;" :patient-id="id" />
      </fm-tab-pane>
      <fm-tab-pane title="消费记录">
        <div style="display: flex;">
          <div style="color: #666;font-size: 15px;">累计消费：<span style="font-weight: 800;color: #000;font-size: 18px;">{{records.allOwe}}元</span></div>
          <div style="color: #666;font-size: 15px;margin-left: 35px;">剩余挂账：<span style="font-weight: 800;color: #000;font-size: 18px;">{{records.allTotalAmount}}元</span></div>
        </div>
        <RecordsTable style="margin-top: 10px;" :patient-id="id" @change="({allOwe, allTotalAmount}) => {records.allOwe = allOwe;records.allTotalAmount = allTotalAmount}" />
      </fm-tab-pane>
    </fm-tabs>

    <fm-modal v-model="status.form" width="70vw">
      <UpdateForm :data="data" @submit="submit"/>
    </fm-modal>
  </div>
</template>

<script>
import { patientRequest } from '@/api'
import TitleCard from './card.vue'
import CasesTable from './cases.vue'
import RecordsTable from './records.vue'
import UpdateForm from '../form.vue'

export default {
  components: { TitleCard, CasesTable, RecordsTable, UpdateForm },
  data () {
    return {
      data: {},
      records: {
        allOwe: 0,
        allTotalAmount: 0,
      },
      status: {
        form: false
      }
    }
  },
  computed: {
    id () {
      return this.$route.query.id || null
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async submit (data) {
      if (data.id) {
        await patientRequest.update(data.id, data)
        this.$notice.success('更新成功')
        this.loadData()
      }
      this.status.form = false
    },
    async loadData () {
      try {
        if (this.id) {
          let data = await patientRequest.get({id: this.id})
          if (data.length) {
            this.data = data[0]
          } else {
            throw new Error('患者信息不存在')
          }
        }
      } catch (error) {
        this.$notice.warning({title: '提示', desc: error.message})
        this.$router.replace({name: 'patients.index'})
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
