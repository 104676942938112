<style scoped>
  .patients-records {
    height: 100%;
  }
</style>

<template>
  <div class="patients-records">
    <fm-table-new
      simple-filter
      :columns="columns"
      :auto-height="true"
      size="small"
      v-loadingx="loading.load"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    </fm-table-new>
    <fm-modal v-model="status.details" title="订单明细" width="700px">
      <RecordsDetails v-if="chooseData" :order-id="chooseData.id"  />
    </fm-modal>
  </div>
</template>

<script>
import { orderRequest } from '@/api'
import TableActions from '@/components/base/TableActions'
import RecordsDetails from '@/views/order/detail/allInfo.vue'

export default {
  components: { TableActions, RecordsDetails },
  props: {
    patientId: { type: Number, default: null }
  },
  data () {
    return {
      dataList: [],
      chooseData: null,
      status: {
        details: false
      },
      loading: {
        load: false
      }
    }
  },
  computed: {
    tableActions () {
      return [
        { key: 'details', label: '详情' }
      ]
    },
    columns () {
      let columns = []

      columns = columns.concat([
        { title: '时间', field: 'createTime' },
        {
          title: '业务类型', field: 'type', render (h, row) {
            return h('div', row.type === 'prescription' ? '处方' : '其他')
          }
        },
        {
          title: '费用合计', field: 'totalAmount', render (h, row) {
            return h('div', row.bill.totalAmount || '-')
          }
        },
        {
          title: '应收金额', field: 'payable', render (h, row) {
            return h('div', row.bill.payable || '-')
          }
        },
        {
          title: '实收金额', field: 'paid', render (h, row) {
            return h('div', row.bill.paid || '-')
          }
        },
        { title: '挂账', field: 'owe' },
        { title: '操作人员', field: 'makeUserName' }
      ])

      columns = columns.concat([
        { title: '操作', field: 'actions', slot: 'actions', fixed: 'right', search: false, export: false, configurable: false }
      ])

      return columns
    }
  },
  methods: {
    tableAction ({action, data}) {
      this.chooseData = data
      if (action === 'details') {
        this.status.details = true
      }
    },
    async loadData () {
      let data = []
      if (this.patientId) {
        data = await orderRequest.get({ patientId: this.patientId })
      } else {
        data = []
      }

      let allOwe = 0
      let allTotalAmount = 0
      data.forEach(row => {
        row.owe = (row.bill.totalAmount || 0) - (row.bill.paid || 0)
        allOwe += row.owe
        allTotalAmount += row.bill.totalAmount || 0
      })

      this.dataList = data

      this.$emit('change', { allOwe, allTotalAmount })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
