<style scoped>
  .illness-case {
    height: 100%;
  }
</style>

<template>
  <div class="illness-case">
    <fm-table-new
      simple-filter
      :columns="columns"
      :auto-height="true"
      size="small"
      v-loadingx="loading.load"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    </fm-table-new>
    <Modal v-model="status.details" :mask-closable="false" width="800px" title="病历详情">
      <CasesInfo v-if="chooseData" :illness-case="chooseData"/>
    </Modal>
  </div>
</template>

<script>
import { illnessCaseRequest } from '@/api'

import TableActions from '@/components/base/TableActions'
import CasesInfo from './cases/details.vue'

import { Modal } from 'view-design'

export default {
  components: { TableActions, CasesInfo, Modal },
  props: {
    patientId: { type: Number, default: null },
    outLoad: { type: Boolean, defualt: false }
  },
  data () {
    return {
      dataList: [],
      chooseData: null,
      status: {
        details: false
      },
      loading: {
        load: false
      }
    }
  },
  computed: {
    tableActions () {
      return [
        { key: 'load', label: '载入' },
        { key: 'details', label: '详情' }
      ]
    },
    columns () {
      let columns = []

      columns = columns.concat([
        { title: '就诊时间', field: 'createTime' },
        { 
          title: '科室', field: 'orgName', render (h, row) {
            return h('div', row.diagnosisData.orgName)
          }
        },
        {
          title: '医生', field: 'executorName', render (h, row) {
            return h('div', row.diagnosisData.executorName)
          }
        },
        {
          title: '诊断', field: 'result', render (h, row) {
            return h('div', row.diagnosisData.result)
          }
        }
      ])

      columns = columns.concat([
        { title: '操作', field: 'actions', slot: 'actions', fixed: 'right', search: false, export: false, configurable: false }
      ])

      return columns
    }
  },
  watch: {
    patientId () {
      this.loadData()
    }
  },
  methods: {
    tableAction ({action, data}) {
      this.chooseData = data
      if (action === 'details') {
        this.status.details = true
      } else if (action === 'load') {
        if (this.outLoad) {
          this.$emit('out-load', {
            data: data,
            router: {
              name: 'patients.details.prescription',
              query: {
                id: this.patientId,
                illnessCaseId: data.id
              }
            }
          })
        } else {
          this.$router.push({
            name: 'patients.details.prescription',
            query: {
              id: this.patientId,
              illnessCaseId: data.id
            }
          })
        }
      }
    },
    async loadData () {
      if (this.patientId) {
        this.loading.load = true
        this.dataList = await illnessCaseRequest.get({patientId: this.patientId})
        this.loading.load = false
      } else {
        this.dataList = []
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
