<style scoped></style>

<template>
  <div class="patient-form">
    <fm-form label-alone label-align="left" :inline="3">
      <fm-form-item label="姓名">
        <fm-input-new v-model="newData.name" placeholder="请输入姓名"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="性别">
        <fm-radio-group name="sex" v-model="newData.sex">
          <fm-radio value="男" label="男"></fm-radio>
          <fm-radio value="女" label="女"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="生日">
        <FmDatePicker :value="newData.birth" @change="(birth) => {newData.birth = birth;}" placeholder="请选择生日" format="Y-M-D"></FmDatePicker>
      </fm-form-item>
      <fm-form-item label="年龄">
        <fm-input-number placeholder="请输入年了" v-model="newData.age"></fm-input-number>
      </fm-form-item>
      <fm-form-item label="手机号">
        <fm-input-new v-model="newData.phone" placeholder="请输入姓名"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="身份证号">
        <fm-input-new v-model="newData.idNo" placeholder="请输入姓名"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="地址">
        <fm-input-new v-model="newData.address" placeholder="请输入姓名"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="过敏史">
        <fm-input-new type="textarea" v-model="newData.hisAllergy" placeholder="请输入过敏史"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="既往史">
        <fm-input-new type="textarea" v-model="newData.hisPast" placeholder="请输入既往史"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="个人史">
        <fm-input-new type="textarea" v-model="newData.hisPersonal" placeholder="请输入个人史"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="家族史">
        <fm-input-new type="textarea" v-model="newData.hisFamily" placeholder="请输入家族史"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="现病史">
        <fm-input-new type="textarea" v-model="newData.hisIllness" placeholder="请输入现病史"></fm-input-new>
      </fm-form-item>
    </fm-form>
    <div style="display: flex;justify-content: flex-end;">
      <fm-btn long @click="$emit('submit', newData)">确定</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      newData: this.data ? JSON.parse(JSON.stringify(this.data)) : {}
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.newData = this.data ? JSON.parse(JSON.stringify(this.data)) : {}
      }
    }
  }
}
</script>
