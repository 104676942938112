<style scoped lang="less">
  .title-card {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        align-items: center;
        display: flex;
        font-size: 16px;
        font-weight: 800;
        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 24px;
          background: #2E6BE5;
          margin-right: 10px;
        }
      }
    }
    .content {}
  }
</style>

<template>
  <div class="title-card">
    <div class="title">
      <div class="left">
        <slot name="title" v-if="$slots.title"></slot>
        <template v-else>{{title}}</template>
      </div>
      <div class="right">
        <slot name="action"></slot>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null }
  }
}
</script>
